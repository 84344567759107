<template>
    <v-chip v-if="theme" dark x-small :color="theme.color">{{theme.name}}</v-chip>
</template>

<style lang="less">
</style>

<script>
export default {
	name: "ThemeChip",
	props: {
		id: { type: String }, // the id of the section
	},
	computed: {
		section(){
			return this.$store.state.sections.data[this.id];
		},
		theme() {
			if( !this.section ){
				return false;
			}
			return this.$store.state.categoryOptions.data[this.section.theme];
		},
	},
};
</script>
